import styled from "styled-components";
import { Divider, Menu } from "antd";

// Sidebar Styles
export const SidebarContainer = styled.div`
  padding-top: 80px;
  width: 280px;
  background: #fff;
  box-shadow: 2px 0px 14px rgba(0, 0, 0, 0.06);
  display: block;
  padding: 80px 15px 15px 15px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  transition: ease-in-out 0.5s;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 1550px) {
    width: 225px;
    padding-top: 64px;
  }

  @media (max-width: 991.98px) {
    z-index: 111;
    margin-top: 65px;
    padding: 0px 15px 15px 15px;
  }

  // scrollbar css
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0em;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 5px;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 57px;

  .ant-image {
    width: 100%;
  }
  .ant-image-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const Navigation = styled.div`
  margin-top: 15px;
  /* position: fixed; */
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 110px);
`;

export const MenuIconWrap = styled.div`
  // transition: box-shadow 0.1s;
  // height: 54px;
  // width: 54px;
  // background: #f6f7f9;
  // border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  ${"" /* padding-top: 4px; */}
  min-width: 18px !important;
  font-size: 25px !important;
  @media (max-width: 1550px) {
    font-size: 20px !important;
  }
`;

export const SidebarDivider = styled(Divider)`
  display: flex;
  clear: both;
  width: 85%;
  ${"" /* min-width: 100%; */}
  margin: auto;
  min-width: auto;
`;
export const SidebarTopMenu = styled(Menu)`
  margin-bottom: 8px;
  .ant-menu-inline {
    border-right: none !important;
    background: transparent;
    background: rgb(0 0 0 / 3%);
  }
  .ant-menu-horizontal > .ant-menu-submenu::after {
    border-bottom: 2px solid transparent !important;
  }
`;
export const SidebarMenu = styled(Menu)`
  border-right: none !important;
  margin-bottom: ${({ mb }) => mb || ""};
  .ant-menu-inline {
    border-right: none !important;
    background: transparent;
    background: rgb(0 0 0 / 3%);
  }
`;
export const MenuDivider = styled(Menu.Divider)``;
export const MenuItem = styled(Menu.Item)`
  overflow: initial !important;
  
  .add__org {
    ${
      "" /* background-color: red !important;
    background: red !important; */
    }
  }
 
  &:not(.ant-menu-item-only-child) {
    height: 50px !important;
    color: #666666;
    ${"" /* padding-left: 40px !important; */}

    @media(max-width: 1550px){
    height: 40px !important;
    color: #666666;
    ${"" /* padding-left: 32px !important; */}
    }
  }

  .ant-badge-count{
    top: 13px;
    background: ${({ theme }) => theme.colors.third};
    right: -10px;
    box-shadow: none;
    min-width: 17px;
    height: 17px;
    padding: 0 0px;
    line-height: 19px;
  }

  .ant-menu-title-content {
    font-weight: bold;
    font-size: 18px;
    line-height: 46px;
    color: #666666;
    a {
      line-height: 46px;
      font-weight: 400;
      font-size: 18px;
      color:{({theme}) => theme.colors.forth};
      
    }
    @media (max-width: 1550px){
      font-size: 12px;
      line-height: 36px;
    

    a {
      line-height: 36px;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 1px;
      color:{({theme}) => theme.colors.forth};
    }
    }
  }

  &:hover {
    .ant-menu-title-content {
      a {
        color: #00366b;
      }
    }
  }

  &.ant-menu-item-selected {
    background: #dee8ff !important;
    overflow: initial !important;
    border-left: 5px solid #4160ac !important;
    &:after {
      border-right: none !important;
    }
    
    .ant-menu-title-content {
      a {
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }
`;
export const SubMenu = styled(Menu.SubMenu)`
  padding: 0 30px !important;
  overflow: initial !important;
  width: 100% !important;
  .ant-menu-submenu-title:hover {
    background-color: ${({ theme }) => theme.colors.secondary} !important;
  }

  &.sub-children {
    .ant-menu-submenu-title {
      height: 40px !important;
      .ant-menu-title-content {
        color: #666666;
      }
      .ant-menu-submenu-arrow {
        color: #ffffff;
      }
    }
  }

  .ant-menu-submenu-title {
    font-weight: bold;
    height: 50px !important;
    overflow: initial !important;
    background: ${({ theme }) => theme.colors.secondary};
    color: white;
    border-radius: 5px;
    padding: 0 10px !important;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    &:active {
      background: ${({ theme }) => theme.colors.secondary}90 !important;
    }
    &:hover {
      color: #ffffff;
    }
  }

  .ant-menu-sub {
    .ant-menu-item {
      color: inherit;
      background: transparent !important;
      overflow: initial !important;
      background: rgb(0 0 0 / 3%);
      &:hover {
        color: #ffffff;
      }
      &:after {
        border-right: none !important;
      }
      &:active {
        background: #eeeeee !important;
      }
    }
  }

  &:not(.ant-menu-submenu-selected) {
    color: #666666;
  }

  &:hover {
    .ant-menu-submenu-arrow {
      color: #ffffff !important;
    }
  }

  &.ant-menu-submenu-selected {
    color: #777777;
    .ant-menu-item-selected {
      background: #dee8ff !important;
    }
    .ant-menu-title-content {
      color: #ffffff;
    }
    .ant-menu-submenu-arrow {
      color: #ffffff;
    }
  }

  @media (max-width: 1550px) {
    padding: 0 24px !important;
    .ant-menu-submenu-title {
      height: 40px !important;
      font-size: 14px;
      letter-spacing: 1px;
    }
  }
`;

export const SideMenuGroup = styled.div``;

export const Spacer = styled.div`
  transition: ease-in-out 0.5s;
  flex: ${({ flex }) => flex || "auto"};
`;

// Sidebar Styles END

// Breadcrumb
export const BreadCrumbsWrapper = styled.div`
  display: inline-block;
  width: 100%;
  /* margin-top: 17px; */
  ${"" /* padding: 30px 30px 0px 320px; */}
  padding-left: 25px;
  border-bottom: 1px solid #f0f0f0;
  ${"" /* background-color: #777777; */}
  margin-bottom: 30px;

  .ant-breadcrumb {
    .ant-breadcrumb-link {
      margin-left: -3px;
      margin-right: -3px;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      color: #777777;
    }
    > :last-child {
      color: #747487 !important;
      a {
        color: #747487 !important;
      }
    }
    > :not(:last-child) {
      color: #777777 !important;
      a {
        color: #777777 !important;
        &:hover {
          color: #ffffff;
        }
      }
    }
    .ant-breadcrumb-separator {
      color: #777777;
    }
  }

  .breadcrumbs_label {
    color: #777777;
  }

  @media (max-width: 1601px) {
    margin-bottom: 15px;
    padding-left: 0;

    .ant-breadcrumb {
      .ant-breadcrumb-link {
        margin-left: 0;
        margin-right: 0;
      }
    }

    ${"" /* padding: 0px 15px 17px 15px; */}
  }
  @media (max-width: 991.98px) {
    margin-bottom: 15px;

    ${"" /* padding: 0px 15px 17px 15px; */}
  }
`;
// Breadcrumb END
