import React from "react";

export function useStickyMessage() {
  const [isActive, setIsActive] = React.useState(false);
  const [isAutoHide, setIsAutoHide] = React.useState(false);
  const [message, setMessage] = React.useState();

  React.useEffect(() => {
    if (isActive && isAutoHide) {
      setTimeout(() => {
        setIsActive(false);
      }, 3000);
    }
  }, [isActive, isAutoHide]);

  const showStickyMessage = (
    msg = "Something went wrong...",
    autoHide = false
  ) => {
    setMessage(msg);
    setIsActive(true);
    setIsAutoHide(autoHide);
  };

  const close = () => {
    setIsActive(false);
  };

  return { isActive, isAutoHide, message, showStickyMessage, close };
}
