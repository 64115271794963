import React, { useEffect, useState } from "react";
import { Col, Row, message } from "antd";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { DetailWrap, StyledButton } from "./ApplicationDetails";
import styled from "styled-components";
import { AiOutlineDashboard } from "react-icons/ai";

const CreditScoreDetails = ({
  onChangeTab,
  userDataObj,
  onApprovalSubmit,
  onDeclineSubmit,
}) => {
  const [creditScore, setCreditScore] = useState([]);

  useEffect(() => {
    if (userDataObj && userDataObj?.credit_score_data?.length) {
      setCreditScore(userDataObj?.credit_score_data);
    } else {
      setCreditScore([]);
    }
  }, [userDataObj]);

  const onApproval = () => {
    if (userDataObj?.is_credit_score_verified !== 1) {
      onApprovalSubmit("credit_score", userDataObj?.user_id);
      message.info("Credit Score Approved");
      // onChangeTab(2);
    } else {
      message.warn("Credit Score Already Approved");
      // onChangeTab(2);
    }
  };

  const onDecline = () => {
    if (userDataObj?.is_credit_score_verified !== 2) {
      onDeclineSubmit("credit_score", userDataObj?.user_id);
      message.info("Credit Score Declined");
    } else {
      message.warn("Credit Score Already Declined");
    }
  };

  return (
    <>
      <DetailWrap>
        <Row>
          <Col span={24}>
            <h1>Credit Score</h1>
          </Col>
          <CreditWrap>
            <Row gutter={[20, 20]} justify="center">
              <Col span={24}>
                <AiOutlineDashboard />
              </Col>
              {creditScore?.length ? (
                <>
                  <Col span={24}>
                    <h2>
                      Credit Score:{" "}
                      {creditScore[0]?.score ? creditScore[0]?.score : ""}
                    </h2>
                  </Col>
                  <Col span={24}>
                    <a
                      href="https://plaid.com/documents/sample-bank-income.pdf"
                      target="blank"
                    >
                      Click here for detailed report
                    </a>
                  </Col>
                </>
              ) : (
                <h2>Credit Data Not Found</h2>
              )}
            </Row>
          </CreditWrap>
          <Row
            // justify="end"
            gutter={[20, 20]}
            className="button__group"
            style={{ alignItems: "center" }}
          >
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              xl={6}
              xxl={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <IoIosArrowBack
                className="nextback__icon"
                onClick={() => onChangeTab(0)}
              />
              <h3 onClick={() => onChangeTab(0)}>Back</h3>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
              <StyledButton
                onClick={() => onApproval()}
                bgColor={"#F67C36"}
                disabled={userDataObj?.is_credit_score_verified === 1}
              >
                Approve
              </StyledButton>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
              <StyledButton
                onClick={() => {
                  onDecline();
                }}
                bgColor={"#2B353F"}
                disabled={userDataObj?.is_credit_score_verified !== 1}
              >
                Decline
              </StyledButton>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              xl={6}
              xxl={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <h3
                style={{ paddingRight: "15px" }}
                onClick={() => onChangeTab(2)}
              >
                Next
              </h3>
              <IoIosArrowForward
                className="nextback__icon"
                onClick={() => onChangeTab(2)}
              />
            </Col>
          </Row>
        </Row>
      </DetailWrap>
    </>
  );
};

export default CreditScoreDetails;

const CreditWrap = styled.div`
  width: 100%;
  margin: 50px 50px;
  padding: 10px;
  border: 1px;
  display: flex;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;
  border-radius: 5px;
  svg {
    width: 100%;
    height: 5em;
  }
  a {
    justify-content: center;
    text-anchor: start;
    display: flex;
  }
  @media (max-width: 648px) {
    margin: 10px 10px;
  }
  @media (max-width: 450px) {
    h2 {
      font-size: 18px;
      font-weight: 400;
    }
  }
`;
