import { Col, Input, Row, Table, message } from "antd";
import { httpAPI } from "api";
import { get, map } from "lodash";
import { DiscountTable } from "pages/Applications/Applications";
import { _merge, dateConverter, formatPhoneNumber } from "pages/utils/fn";
import React, { useCallback, useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getOrganizaion } from "store/reducers/user.reducer";
import { Heading } from "ui/Heading";
import { Container } from "ui/Layout/Container";
import _ from "lodash";
import useDebounce from "pages/utils/hooks/useDebounce";

function Organization() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [searchBarText, setSearchBarText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const Search = Input.Search;
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageNumber: 1,
    total: 0,
    size: "small",
  });

  const emitEmpty = () => {
    setSearchBarText("");
  };
  const deBounceSearchBarText = useDebounce(searchBarText.trim(), 1000);

  const suffix = <MdCancel onClick={() => emitEmpty()} />;

  const columns = [
    {
      title: "Organization",
      dataIndex: "org_name",
    },
    {
      title: "Property Name",
      dataIndex: "unit_name",
    },
    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "State",
      dataIndex: "state",
    },
    {
      title: "Zipcode",
      dataIndex: "zipcode",
    },
    {
      title: "Phone",
      dataIndex: "phonenumber",
      render: (_, record) => <>{formatPhoneNumber(record?.phonenumber)}</>,
    },
    {
      title: "# of Units",
      dataIndex: "numberOfUnit",
    },
  ];

  const handleTableChange = (newPagination, filters, sorter) => {
    setLoading(true);
    newPagination.pageNumber = newPagination.current;
    getOrg(newPagination, searchBarText);
    setPagination((p) => ({ ...p, ...newPagination, filters, sorter }));
  };

  const onSearch = (e) => {
    setSearchBarText(e.target.value);
  };

  useEffect(() => {
    if (deBounceSearchBarText?.length >= 3) {
      setLoading(true);
      httpAPI(
        `/organizations/all?pageSize=${10}&pageNumber=${1}&query=${encodeURIComponent(
          deBounceSearchBarText
        )}`,
        "",
        "GET"
      ).then((res) => {
        setPagination((p) => ({ ...p, total: res?.data?.total }));
        let value = res?.data?.Data;
        const launchOptimistic = value?.map((elem, idx) => ({
          ...elem,
          key: idx,
        }));
        setSearchData(launchOptimistic);
        setLoading(false);
      });
    } else {
      let newPagination = { ...pagination };
      getOrg(newPagination, searchBarText);
    }
  }, [deBounceSearchBarText]);

  const getOrg = useCallback(
    (pagination, search = "") => {
      setLoading(true);
      httpAPI(
        `/organizations/all?pageSize=${pagination.pageSize}&pageNumber=${
          pagination.pageNumber
        }&query=${encodeURIComponent(search)}`,
        "",
        "GET"
      )
        .then((payload) => {
          if (payload?.data) {
            setPagination((p) => ({ ...p, total: payload?.data?.total }));
            let value = payload?.data?.Data;
            const launchOptimistic = value?.map((elem, idx) => ({
              ...elem,
              key: idx,
            }));
            setSearchData(launchOptimistic);
            setLoading(false);
          } else {
            setSearchData([]);
          }
        })
        .catch((error) => {
          setLoading(false);

          if (error) {
            message.error(error.message);
          }
        });
    },

    [pagination]
  );

  return (
    <Container paddingSm="40px 40px 0 265px">
      <Row justify="space-between">
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Heading>
            <Link to="/organizations">Organizations</Link>
          </Heading>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={8}
          className="search__filter"
        >
          <Search
            ref={(ele) => searchBarText === ele}
            suffix={suffix}
            placeholder="Search..."
            value={searchBarText}
            onChange={onSearch}
            onPressEnter={onSearch}
          />
        </Col>
      </Row>

      <DiscountTable>
        <Table
          defaultExpandAllRows={true}
          columns={columns}
          dataSource={[...searchData]}
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
          scroll={{ x: 1300 }}
          onRow={(item) => ({
            onClick: (event) => {
              event.isDefaultPrevented();
            },
          })}
        />
      </DiscountTable>
    </Container>
  );
}

export default Organization;
