/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useStickyMessage } from "pages/utils/hooks/useStickyMessage";
import {
  useNavigate,
  Routes,
  Link,
  useLocation,
  Route,
} from "react-router-dom";
import { Breadcrumb } from "antd";
import { me } from "../../store/reducers/user.reducer";
import { useDispatch } from "react-redux";
import Applications from "pages/Applications/Applications";
import { useWindowSize } from "pages/utils/hooks/useWindowSize";
import Dashboard from "pages/dashboard";
import ApplicationDetails from "components/tenants/ApplicationDetails";
import Organization from "pages/organization/Organization";

function Content() {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  const [headerHeight, setHeaderHeight] = useState("80px");

  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets;

  // Setting height w.r.t screen width
  useEffect(() => {
    if (width < 1551) {
      setHeaderHeight("65px");
    } else {
      setHeaderHeight("80px");
    }
  }, [width, location.pathname]);

  const {
    isActive,
    message: stickyMessage,
    isAutoHide,
    showStickyMessage,
    close,
  } = useStickyMessage();

  // Function to navigate to Home page
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  // Dispatch action w.r.t event
  useEffect(() => {
    dispatch(me());
  }, []);

  return (
    <div style={{ marginTop: headerHeight }}>
      {/*
      <StickyMessage
        isActive={isActive}
        message={stickyMessage}
        isAutoHide={isAutoHide}
        onClick={() => {
          close();
          navigate("/");
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      />
      {/* {!ignoreBreadCrumbs.includes(location.pathname) && ( */}
      {/*
      <BreadCrumbsWrapper>
        <Breadcrumb>{breadcrumbItems}</Breadcrumb>
      </BreadCrumbsWrapper>
      */}
      {/* <BrowserRouter> */}

      {/* element={
            localStorage.getItem("idToken") &&
            localStorage.getItem("user_id") ? (
              <Navigate to={currentPath} />
            ) : (
              <LoginForm />
            )
          } */}

      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route exact={true} path="/tenants" element={<Applications />} />
        <Route exact={true} path="/organizations" element={<Organization />} />
        <Route
          exact={true}
          path="/application-details/:id"
          element={<ApplicationDetails />}
        />
        {/* <Route exact={true} path="/no-listings" element={<NoListing />} />
        <Route exact={true} path="/property/:id" element={<Listings />} />
        <Route exact={true} path="/subunit/:id" element={<SubUnit />} />
        <Route exact={true} path="/listings/:id" element={<ApartmentsView />} />
        <Route exact={true} path="/applications" element={<Applications />} />
        <Route exact={true} path="/messages" element={<Messages />} />
        <Route exact={true} path="/tenants" element={<Tenants />} />
        <Route exact={true} path="/discount-code" element={<DiscountCode />} />
        <Route exact={true} path="/transactions" element={<Invoices />} />
        <Route exact={true} path="/my-account" element={<MyAccount />} />
        <Route exact={true} path="/support" element={<Support />} />
        <Route exact={true} path="/setting" element={<Settings />} />
        <Route exact={true} path="/application" element={<ApplicationTable />} />
        <Route
          exact={true}
          path="/tenant-details/:id"
          element={<TenantDetails />}
        />
        <Route
          exact={true}
          path="/tenants-invoices-details/:id"
          element={<TenantsInvoicesDetails />}
        />
        <Route
          exact={true}
          path="/tenants-ledger/:id"
          element={<TenantLedger />}
        />
        <Route
          exact={true}
          path="/support/callormail"
          element={<CallOrEmail />}
        /> */}
      </Routes>
      {/* </BrowserRouter> */}
    </div>
  );
}

export default Content;
