const interceptorInvite = (axios) => {
  const requestHandler = (request) => {
    const access_token = localStorage.getItem("idToken");
    if (access_token) {
      // Modify request here
      request.headers.Authorization = access_token;
    }
    return request;
  };

  axios.interceptors.request.use((request) => requestHandler(request));

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const { response } = error;

      if ((response && response.status === 401) || response.status === 500) {
        // localStorage.clear();
        return Promise.reject(response.data);
      }
      return Promise.reject(response ? response?.data || error : error);
    }
  );
  return axios;
};

export default interceptorInvite;
