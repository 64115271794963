import { useEffect, useMemo } from "react";
import "./App.css";
import "antd/dist/antd.css";
import { ThemeProvider } from "styled-components";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Login from "pages/login";
import PrivateRoute from "routes/PrivateRoute";
import UnauthorizedRoute from "routes/UnauthorizedRoute";
import Layout from "components/layout/Layout";

const primary = "#ffffff";
const secondary = "#4160AC";
const third = "#F67C36";
const forth = "#2B353F";

function App() {
  const navigate = useNavigate();

  const theme = useMemo(() => ({
    colors: {
      primary: primary,
      secondary: secondary,
      third: third,
      forth: forth,
    },
  }));

  // useEffect(() => {
  //   const idToken = localStorage.getItem("idToken")
  //   if (idToken) {
  //     // navigate("/listings")
  //     navigate("/")
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [localStorage.getItem("idToken")])

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route element={<UnauthorizedRoute />}>
          <Route path="/login" element={<Login />} />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route path="*" element={<Layout />} />
        </Route>
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
