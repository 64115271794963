import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import ApplicationDetails from "./ApplicationDetails";
import { IoIosClose } from "react-icons/io";

const TenantDetailModal = ({ modalState, onCancel, userName, updatedData }) => {
  return (
    <Modal
      getContainer={() => document.querySelector("#modal-container")}
      visible={modalState}
      onCancel={() => {
        onCancel(false);
      }}
      width={1600}
      footer={null}
      style={{ borderRadius: "5px", padding: "50px" }}
      className="modal_wrapper"
      closeIcon={<IoIosClose className="modal_close_icon" />}
      maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.8)" }}
      focusTriggerAfterClose={false}
      destroyOnClose={true}
    >
      <ApplicationDetails
        updatedData={updatedData}
        userName={userName}
        onCancel={onCancel}
      />
    </Modal>
  );
};

export default TenantDetailModal;
