import React from "react";
import styled from "styled-components";
import { WatchSvg } from "../../assets/svgs";

function FetureSoon() {
  return (
    <DashboardWrap>
      <WrapContant>
        <WatchSvg className="svg__icon" />
        <h2>This feature will launch soon</h2>
        <p>
          To give you the greatest available solution, we are constantly
          developing cutting-edge features and functionality. Soon, this feature
          will be available for your use. In the meantime, enjoy easily listing
          your properties and adding tenants.
        </p>
      </WrapContant>
    </DashboardWrap>
  );
}

export default FetureSoon;

const WrapContant = styled.div`
  background: #ffffff;
  padding: 65px 35px 50px 35px;
  width: 37%;
  text-align: center;
  margin: auto;
  position: absolute;
  top: 40%;
  left: 40%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  svg,
  .svg__icon {
    position: absolute;
    top: -10%;
    text-align: center;
    align-items: center;
    justify-content: center;
    left: 45%;
  }
  h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    color: #2b353f;
    width: 74%;
    margin: auto;
    margin-bottom: 25px;
  }
  p {
    margin: auto;
    width: 80%;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #aaaaaa;
  }

  @media (max-width: 1550px) {
    width: 35%;

    h2 {
      font-size: 32px;
      line-height: 40px;
      width: 59%;
      margin: auto;
      margin-bottom: 25px;
    }
    p {
      margin: auto;
      width: 95%;
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
      text-align: center;
      color: #aaaaaa;
    }
  }
`;

const DashboardWrap = styled.div`
  ${"" /* 50px 50px 50px 330px */}
  width:100%;
  position: fixed;
  top: 80px;
  left: 280px;
  right: 0;
  bottom: 0;
  z-index: 1000;
  height: 100%;
  background: rgba(235, 235, 235, 0.2);
  backdrop-filter: blur(5px);

  @media (max-width: 1550px) {
    top: 65px;
    left: 225px;
  }
`;
