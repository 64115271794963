import { Col, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  AddedReference,
  DetailsBox,
  ViewAddedReference,
  ViewAllRental,
} from "./EmployerDetails";
import { LessSvg, PlusSvg } from "assets/svgs";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { dateConverter, formatPhoneNumber } from "pages/utils/fn";
import { DetailWrap, StyledButton } from "./ApplicationDetails";

const RentalHistory = ({
  onChangeTab,
  userDataObj,
  onApprovalSubmit,
  onDeclineSubmit,
}) => {
  const [newSetalFields, setNewSetalFields] = useState([]);
  const [openIndexs, setOpenIndexs] = useState([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  ]);

  useEffect(() => {
    if (userDataObj && userDataObj?.rental_history?.references?.length) {
      setNewSetalFields(userDataObj?.rental_history?.references);
    }
  }, [userDataObj]);

  // filter data
  const openIndexFun = (idx) => {
    if (openIndexs?.includes(idx)) {
      var filteredArray = openIndexs?.filter((e) => {
        return e !== idx;
      });
      setOpenIndexs(filteredArray);
    } else {
      setOpenIndexs((val) => [...val, idx]);
    }
  };

  const onApproval = () => {
    if (userDataObj?.is_rental_history_verified !== 1) {
      onApprovalSubmit("rental_history", userDataObj?.user_id);
      message.info("Rental history Approved");
      // onChangeTab(5);
    } else {
      message.warn("Rental history Already Approved");
      // onChangeTab(5);
    }
  };

  const onDecline = () => {
    if (userDataObj?.is_rental_history_verified !== 2) {
      onDeclineSubmit("rental_history", userDataObj?.user_id);
      message.info("Rental history Declined");
    } else {
      message.warn("Rental history Already Declined");
    }
  };

  return (
    <>
      <DetailWrap>
        <Row>
          <Col span={24}>
            <h1>Rental History</h1>
          </Col>
          {newSetalFields?.length ? (
            <>
              {newSetalFields?.map(
                (
                  {
                    propertyType,
                    propertyName,
                    address,
                    city,
                    state,
                    zipcode,
                    currentRent,
                    managerName,
                    phone,
                    dateMovedIn,
                  },
                  idx
                ) => (
                  <>
                    <ViewAllRental
                      mb={openIndexs?.includes(idx) ? "0" : "25px"}
                      key={idx}
                    >
                      <AddedReference
                        bb={
                          openIndexs?.includes(idx)
                            ? "none"
                            : "1px solid #dddddd"
                        }
                        onClick={() => openIndexFun(idx)}
                      >
                        <Row
                          justify="space-between"
                          style={{ alignItems: "center", padding: "0px" }}
                        >
                          <Col>
                            <Row gutter={[10, 10]}>
                              <Col>
                                <p className="prop_name">{propertyName}</p>
                              </Col>
                              <Col>
                                {" "}
                                <span>{propertyType}</span>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            {openIndexs?.includes(idx) ? (
                              <>
                                <LessSvg
                                  style={{ marginBottom: "5px" }}
                                  className="less__svg"
                                />
                              </>
                            ) : (
                              <>
                                <PlusSvg />
                              </>
                            )}
                          </Col>
                        </Row>
                      </AddedReference>
                    </ViewAllRental>

                    {openIndexs?.includes(idx) ? (
                      <ViewAddedReference>
                        <Row justify="space-between" gutter={[15, 15]}>
                          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                            <h2>Address</h2>
                            <Row justify="space-between" gutter={[2, 2]}>
                              <Col span={8}>
                                <DetailsBox>
                                  <p>Address</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{address}</p>
                                </DetailsBox>
                              </Col>

                              <Col span={8}>
                                <DetailsBox>
                                  <p>City</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{city}</p>
                                </DetailsBox>
                              </Col>

                              <Col span={8}>
                                <DetailsBox>
                                  <p>State</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{state}</p>
                                </DetailsBox>
                              </Col>

                              <Col span={8}>
                                <DetailsBox>
                                  <p>Zipcode</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{zipcode ? zipcode : `${" "}`}</p>
                                </DetailsBox>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                            <h2>Contact Details</h2>
                            <Row justify="space-between" gutter={[2, 2]}>
                              <Col span={8}>
                                <DetailsBox>
                                  <p>Current rent</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{currentRent}</p>
                                </DetailsBox>
                              </Col>

                              <Col span={8}>
                                <DetailsBox>
                                  <p>Manager’s name</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{managerName}</p>
                                </DetailsBox>
                              </Col>

                              <Col span={8}>
                                <DetailsBox>
                                  <p>Phone</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{formatPhoneNumber(phone)}</p>
                                </DetailsBox>
                              </Col>

                              <Col span={8}>
                                <DetailsBox>
                                  <p>Date moved in</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  {dateMovedIn ? (
                                    <p>{dateConverter(dateMovedIn)}</p>
                                  ) : (
                                    ""
                                  )}
                                </DetailsBox>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </ViewAddedReference>
                    ) : (
                      ""
                    )}
                  </>
                )
              )}
            </>
          ) : (
            <>
              <h2>No Data Found</h2>
            </>
          )}
          <Row
            justify="space-between"
            className="button__group"
            style={{ alignItems: "center" }}
            gutter={[20, 20]}
          >
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              xl={6}
              xxl={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <IoIosArrowBack
                className="nextback__icon"
                onClick={() => onChangeTab(3)}
              />
              <h3 onClick={() => onChangeTab(3)}>Back</h3>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
              <StyledButton
                bgColor={"#F67C36"}
                onClick={() => {
                  if (newSetalFields?.length) {
                    onApproval();
                  } else {
                    message.warn("No Data Found to Approve");
                  }
                }}
                disabled={userDataObj?.is_rental_history_verified === 1}
              >
                Approve
              </StyledButton>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
              <StyledButton
                bgColor={"#2B353F"}
                onClick={() => {
                  if (newSetalFields?.length) {
                    onDecline();
                  } else {
                    message.warn("No Data Found to Decline");
                  }
                }}
                disabled={userDataObj?.is_rental_history_verified !== 1}
              >
                Decline
              </StyledButton>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              xl={6}
              xxl={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <h3
                style={{ paddingRight: "15px" }}
                onClick={() => onChangeTab(5)}
              >
                Next
              </h3>
              <IoIosArrowForward
                className="nextback__icon"
                onClick={() => onChangeTab(5)}
              />
            </Col>
          </Row>
        </Row>
      </DetailWrap>
    </>
  );
};

export default RentalHistory;
