import styled from "styled-components";
import { Select, DatePicker, Button } from "antd";

const { RangePicker } = DatePicker;

export const SelectWrap = styled(Select)`
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  height: 40px !important;
  .ant-select-selection-placeholder {
    color: #2b353f !important;
  }
  :hover {
    border-color: #aaaaaa !important;
  }

  .ant-select-selector {
    position: relative;
    background-color: ${({ bgcolor }) => bgcolor || "#fff"} !important;
    border: none !important;
    border-radius: 5px !important;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 40px !important;
    align-items: center;
  }
  .ant-select:hover {
    box-shadow: 0 0 0 2px rgb(0, 0, 0, 0.1) !important;
  }
  ,
  .ant-select-selector {
    border-color: #aaaaaa !important;
  }
  .ant-select-focused {
    box-shadow: 0 0 0 2px rgb(0, 0, 0, 0.1) !important;
  }

  @media (max-width: 1550px) {
    height: 32px !important;
    font-size: 13px !important;

    .ant-select-selection-placeholder {
      font-size: 13px;
    }

    .ant-select-selector {
      height: 32px !important;
    }
  }
`;
export const RangePickerWrap = styled(RangePicker)`
  font-size: 18px;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  height: 40px !important;
  border-radius: 5px !important;
  border: none !important;

  .ant-picker-range,
  .ant-picker-active-bar {
    bottom: -1px;
    height: 2px;
    margin-left: 11px;
    background: #aaaaaa !important;
    opacity: 0;
    transition: all 0.3s ease-out;
    pointer-events: none;
  }
  .ant-picker-panel-container {
    background: #aaaaaa !important;
  }
  .ant-picker-input input::placeholder,
  .ant-picker-suffix {
    color: ${({ theme }) => theme.colors.forth};
  }

  @media (max-width: 1550px) {
    ${'' /* height: 32px !important; */}
    height: ${({ smHeight }) => smHeight || "32px"} !important;

    .ant-picker-input input::placeholder,
    .ant-picker-suffix,
    .ant-picker-input > input {
      font-size: 13px;
    }
  }
`;

export const ExportButton = styled(Button)`
  padding: 0;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  height: 40px !important;
  border-radius: 5px !important;
  border: 1px solid ${({ theme }) => theme.colors.third} !important;
  ${"" /* background: #666666; */}
  background-color: ${({ theme }) => theme.colors.third};
  color: white;
  font-size: 18px;
  text-transform: uppercase;
  .anticon {
    transform: rotate(180deg) !important;
  }
  :hover,
  :active,
  :focus {
    background: #f9a97c;
    color: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 1550px) {
    height: 32px !important;
    font-size: 14px;
  }
`;

export const ChartBoardWrap = styled.div`
  border-radius: 5px;
  h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #2b353f;
  }
  @media (max-width: 1550px) {
    h2 {
      font-size: 18px;
      line-height: 23px;
    }
  }
  @media (max-width: 991.98px) {
    margin-top: 10px;
  }

  .ant-col {
    display: flex !important;
  }

  P {
    margin: 0;
  }
  .line__titleA,
  .line__titleB {
    align-items: center;
    justify-content: space-evenly;
    color: #858585;
  }
  .line__titleA {
  }
`;

export const TableBoardWrap = styled.div`
  margin-top: 10px;
  border-radius: 5px;

  .ant-table-thead > tr > th {
    background-color: #dee8ff;
    font-weight: 700;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.forth};
  }

  @media (max-width: 1550px) {
    .ant-table {
      font-size: 12px;
    }
    .ant-table-thead > tr > th {
      font-size: 12px;
      padding: 10px 0 10px 10px;
    }
    .ant-table-tbody > tr > td {
      padding: 8px 8px;
    }
  }
`;
export const FilterWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const SelectFilterWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
  width: 50%;
  @media (max-width: 525px) {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }
  @media (max-width: 375px) {
    .mobile__wd {
      max-width: 155px;
    }
  }
  @media (max-width: 325px) {
    .mobile__wd {
      max-width: 135px;
    }
  }
`;
export const DateFilterWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 46%;
  @media (max-width: 525px) {
    width: 100%;
  }
`;
