import React, { useEffect, useState } from "react";
import { DetailWrap, StyledButton } from "./ApplicationDetails";
import { Col, Popover, Row, Table, message } from "antd";
import {
  AddedReference,
  DetailsBox,
  ViewAddedReference,
  ViewAllRental,
} from "./EmployerDetails";
import { dateConverter, formatPhoneNumber, formatSsn } from "pages/utils/fn";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { upperCase } from "lodash";
import { ImagePreview } from "components/imagePreview";
import { DiscountTable } from "pages/Applications/Applications";

const GeneralProfile = ({
  onChangeTab,
  onApprovalSubmit,
  onDeclineSubmit,
  userDataObj,
  onCancel,
}) => {
  const [profileData, setProfileData] = useState([]);
  const [memberData, setMemberData] = useState([]);

  useEffect(() => {
    if (userDataObj && Object.keys(userDataObj?.general_profile || {}).length) {
      setProfileData(userDataObj?.general_profile);
    }
    if (userDataObj && userDataObj?.members?.length) {
      setMemberData(userDataObj?.members);
    }
  }, [userDataObj]);

  const onApproval = () => {
    if (userDataObj?.is_general_profile_verified !== 1) {
      onApprovalSubmit("general_profile", userDataObj?.user_id);
      message.info("General Profile Approved");
      // onChangeTab(1);
    } else {
      message.warn("General Profile Already Approved");
      // onChangeTab(1);
    }
  };

  const onDecline = () => {
    if (userDataObj?.is_general_profile_verified !== 2) {
      onDeclineSubmit("general_profile", userDataObj?.user_id);
      message.info("General Profile Declined");
    } else {
      message.warn("General Profile Already Declined");
    }
  };

  // Content for pop over
  const content = (
    <div>
      <ImagePreview
        style={{
          width: "100%",
          padding: "20px",
          minHeight: "200px",
          maxHeight: "200px",
        }}
        isCoverDisProp={false}
        isDeleteDisProp={false}
        src={userDataObj?.id_proof?.frontProof?.path}
      />
      <ImagePreview
        style={{
          width: "100%",
          padding: "20px",
          minHeight: "200px",
          maxHeight: "200px",
        }}
        isCoverDisProp={false}
        isDeleteDisProp={false}
        src={userDataObj?.id_proof?.backProof?.path}
      />
    </div>
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "memberName",
    },
    {
      title: "Phone Number",
      dataIndex: "memberNumber",
      render: (record) => formatPhoneNumber(record),
    },
  ];

  return (
    <>
      <DetailWrap>
        <Row>
          <Col span={24}>
            <h1>General Profile</h1>
          </Col>
          {Object?.keys(profileData || {})?.length ? (
            <ViewAllRental>
              <AddedReference>
                <Row justify="space-between">
                  <Col>
                    <h2>Profile Details</h2>
                  </Col>
                  <Col>
                    ID Type :{" "}
                    {Object.keys(userDataObj?.id_proof).length ? (
                      <Popover
                        overlayClassName="Id_popover"
                        content={content}
                        title="ID Preview"
                        placement="bottomRight"
                      >
                        <span className="id_type">
                          {" "}
                          {userDataObj?.id_proof?.IDtype}
                        </span>
                      </Popover>
                    ) : (
                      <span className="id_type">NA</span>
                    )}
                  </Col>
                </Row>
              </AddedReference>
              <ViewAddedReference>
                <Row justify="space-between" gutter={[15, 15]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    <Row justify="space-between" gutter={[2, 2]}>
                      <Col span={8}>
                        <DetailsBox>
                          <p>First Name</p>
                        </DetailsBox>
                      </Col>
                      <Col span={16}>
                        <DetailsBox color="#000000">
                          <p>{upperCase(profileData?.firstLegalName)}</p>
                        </DetailsBox>
                      </Col>
                      <Col span={8}>
                        <DetailsBox>
                          <p>Middle Name</p>
                        </DetailsBox>
                      </Col>
                      <Col span={16}>
                        <DetailsBox color="#000000">
                          <p>{upperCase(profileData?.MiddleLegalName)}</p>
                        </DetailsBox>
                      </Col>
                      <Col span={8}>
                        <DetailsBox>
                          <p>Last Name</p>
                        </DetailsBox>
                      </Col>
                      <Col span={16}>
                        <DetailsBox color="#000000">
                          <p>{upperCase(profileData?.lastLegalName)}</p>
                        </DetailsBox>
                      </Col>
                      <Col span={8}>
                        <DetailsBox>
                          <p>Gender</p>
                        </DetailsBox>
                      </Col>
                      <Col span={16}>
                        <DetailsBox color="#000000">
                          <p>{profileData?.sex}</p>
                        </DetailsBox>
                      </Col>
                      <Col span={8}>
                        <DetailsBox>
                          <p>Birth Date</p>
                        </DetailsBox>
                      </Col>
                      <Col span={16}>
                        <DetailsBox color="#000000">
                          <p>{dateConverter(profileData?.birthdate)}</p>
                        </DetailsBox>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    <Row justify="space-between" gutter={[2, 2]}>
                      <Col span={8}>
                        <DetailsBox>
                          <p>SSN</p>
                        </DetailsBox>
                      </Col>
                      <Col span={16}>
                        <DetailsBox color="#000000">
                          <p>{formatSsn(profileData?.socialSecurityNumber)}</p>
                        </DetailsBox>
                      </Col>
                      <Col span={8}>
                        <DetailsBox>
                          <p>Address</p>
                        </DetailsBox>
                      </Col>
                      <Col span={16}>
                        <DetailsBox color="#000000">
                          <p>{profileData?.address}</p>
                        </DetailsBox>
                      </Col>
                      <Col span={8}>
                        <DetailsBox>
                          <p>City</p>
                        </DetailsBox>
                      </Col>
                      <Col span={16}>
                        <DetailsBox color="#000000">
                          <p>{profileData?.city}</p>
                        </DetailsBox>
                      </Col>
                      <Col span={8}>
                        <DetailsBox>
                          <p>State</p>
                        </DetailsBox>
                      </Col>
                      <Col span={16}>
                        <DetailsBox color="#000000">
                          <p>{profileData?.state}</p>
                        </DetailsBox>
                      </Col>
                      <Col span={8}>
                        <DetailsBox>
                          <p>Zip</p>
                        </DetailsBox>
                      </Col>
                      <Col span={16}>
                        <DetailsBox color="#000000">
                          <p>{profileData?.zipcode}</p>
                        </DetailsBox>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </ViewAddedReference>
            </ViewAllRental>
          ) : (
            <>
              <h2>No Data Found</h2>
            </>
          )}
          {memberData?.length ? (
            <>
              <Row gutter={[10, 10]} style={{ width: "100%" }}>
                <Col span={24}>
                  <h1>Members</h1>
                </Col>
                <Col span={24}>
                  <DiscountTable>
                    <Table
                      columns={columns}
                      dataSource={[...memberData]}
                      pagination={false}
                    />
                  </DiscountTable>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
          <Row
            justify="space-between"
            className="button__group"
            style={{ alignItems: "center" }}
            gutter={[20, 20]}
          >
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              xl={6}
              xxl={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <IoIosArrowBack
                className="nextback__icon"
                onClick={() => onCancel(false)}
              />
              <h3 onClick={() => onCancel(false)}>Back</h3>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
              <StyledButton
                bgColor="#F67C36"
                onClick={() => {
                  if (Object?.keys(profileData || {})?.length) {
                    onApproval();
                  } else {
                    message.warn("No Data Found to Approve");
                  }
                }}
                disabled={userDataObj?.is_general_profile_verified === 1}
              >
                Approve
              </StyledButton>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
              <StyledButton
                bgColor={"#2B353F"}
                onClick={() => {
                  if (Object?.keys(profileData || {})?.length) {
                    onDecline();
                  } else {
                    message.warn("No Data Found to Deline");
                  }
                }}
                disabled={userDataObj?.is_general_profile_verified !== 1}
              >
                Decline
              </StyledButton>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              xl={6}
              xxl={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <h3
                style={{ paddingRight: "15px" }}
                onClick={() => onChangeTab(1)}
              >
                Next
              </h3>
              <IoIosArrowForward
                className="nextback__icon"
                onClick={() => onChangeTab(1)}
              />
            </Col>
          </Row>
        </Row>
      </DetailWrap>
    </>
  );
};

export default GeneralProfile;
