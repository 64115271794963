import React from "react";

const watchSvg = ({
  color = "#666666",
  width = "70",
  height = "70",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="35" cy="35" r="35" fill="white" />
      <path
        d="M34.5 56.5C46.6503 56.5 56.5 46.6503 56.5 34.5C56.5 22.3497 46.6503 12.5 34.5 12.5C22.3497 12.5 12.5 22.3497 12.5 34.5C12.5 46.6503 22.3497 56.5 34.5 56.5Z"
        fill="#F1F3F4"
      />
      <path
        d="M34.5 58C29.8521 58 25.3087 56.6218 21.4441 54.0395C17.5796 51.4573 14.5675 47.7871 12.7888 43.4931C11.0102 39.199 10.5448 34.4739 11.4516 29.9154C12.3583 25.3568 14.5965 21.1695 17.883 17.883C21.1695 14.5965 25.3568 12.3583 29.9154 11.4516C34.4739 10.5448 39.199 11.0102 43.4931 12.7888C47.7871 14.5675 51.4573 17.5796 54.0395 21.4441C56.6218 25.3087 58 29.8521 58 34.5C57.9929 40.7304 55.5147 46.7036 51.1091 51.1091C46.7036 55.5147 40.7304 57.9929 34.5 58ZM34.5 14C30.4455 14 26.482 15.2023 23.1108 17.4549C19.7396 19.7075 17.1121 22.9091 15.5605 26.655C14.0089 30.4009 13.6029 34.5228 14.3939 38.4994C15.1849 42.476 17.1373 46.1287 20.0043 48.9957C22.8713 51.8627 26.524 53.8151 30.5007 54.6061C34.4773 55.3971 38.5991 54.9911 42.345 53.4395C46.0909 51.8879 49.2926 49.2604 51.5451 45.8892C53.7977 42.518 55 38.5545 55 34.5C54.9939 29.0649 52.8322 23.8542 48.989 20.011C45.1458 16.1679 39.9351 14.0061 34.5 14Z"
        fill="#F28080"
      />
      <path
        d="M12.5 36C12.1022 36 11.7206 35.842 11.4393 35.5607C11.158 35.2794 11 34.8978 11 34.5C11.0071 28.2696 13.4853 22.2964 17.8909 17.8909C22.2964 13.4853 28.2696 11.0071 34.5 11C34.8978 11 35.2794 11.158 35.5607 11.4393C35.842 11.7206 36 12.1022 36 12.5C36 12.8978 35.842 13.2794 35.5607 13.5607C35.2794 13.842 34.8978 14 34.5 14C29.0649 14.0061 23.8542 16.1679 20.011 20.011C16.1679 23.8542 14.0061 29.0649 14 34.5C14 34.8978 13.842 35.2794 13.5607 35.5607C13.2794 35.842 12.8978 36 12.5 36Z"
        fill="#F2BF80"
      />
      <path
        d="M34.5005 36.0005C34.3035 36.0006 34.1084 35.9618 33.9263 35.8865C33.7443 35.8112 33.5788 35.7007 33.4395 35.5615L27.4395 29.5615C27.1581 29.2801 27 28.8984 27 28.5005C27 28.1025 27.1581 27.7209 27.4395 27.4395C27.7209 27.1581 28.1025 27 28.5005 27C28.8984 27 29.2801 27.1581 29.5615 27.4395L34.5005 32.3795L43.4395 23.4395C43.7209 23.1581 44.1025 23 44.5005 23C44.8984 23 45.2801 23.1581 45.5615 23.4395C45.8429 23.7209 46.001 24.1025 46.001 24.5005C46.001 24.8984 45.8429 25.2801 45.5615 25.5615L35.5615 35.5615C35.4221 35.7007 35.2567 35.8112 35.0746 35.8865C34.8926 35.9618 34.6975 36.0006 34.5005 36.0005Z"
        fill="#F28080"
      />
      <path
        d="M34.5 22C34.1022 22 33.7206 21.842 33.4393 21.5607C33.158 21.2794 33 20.8978 33 20.5V18.5C33 18.1022 33.158 17.7206 33.4393 17.4393C33.7206 17.158 34.1022 17 34.5 17C34.8978 17 35.2794 17.158 35.5607 17.4393C35.842 17.7206 36 18.1022 36 18.5V20.5C36 20.8978 35.842 21.2794 35.5607 21.5607C35.2794 21.842 34.8978 22 34.5 22Z"
        fill="#AAADBF"
      />
      <path
        d="M34.5 52C34.1022 52 33.7206 51.842 33.4393 51.5607C33.158 51.2794 33 50.8978 33 50.5V48.5C33 48.1022 33.158 47.7206 33.4393 47.4393C33.7206 47.158 34.1022 47 34.5 47C34.8978 47 35.2794 47.158 35.5607 47.4393C35.842 47.7206 36 48.1022 36 48.5V50.5C36 50.8978 35.842 51.2794 35.5607 51.5607C35.2794 51.842 34.8978 52 34.5 52Z"
        fill="#AAADBF"
      />
      <path
        d="M20.5 36H18.5C18.1022 36 17.7206 35.842 17.4393 35.5607C17.158 35.2794 17 34.8978 17 34.5C17 34.1022 17.158 33.7206 17.4393 33.4393C17.7206 33.158 18.1022 33 18.5 33H20.5C20.8978 33 21.2794 33.158 21.5607 33.4393C21.842 33.7206 22 34.1022 22 34.5C22 34.8978 21.842 35.2794 21.5607 35.5607C21.2794 35.842 20.8978 36 20.5 36Z"
        fill="#AAADBF"
      />
      <path
        d="M50.5 36H48.5C48.1022 36 47.7206 35.842 47.4393 35.5607C47.158 35.2794 47 34.8978 47 34.5C47 34.1022 47.158 33.7206 47.4393 33.4393C47.7206 33.158 48.1022 33 48.5 33H50.5C50.8978 33 51.2794 33.158 51.5607 33.4393C51.842 33.7206 52 34.1022 52 34.5C52 34.8978 51.842 35.2794 51.5607 35.5607C51.2794 35.842 50.8978 36 50.5 36Z"
        fill="#AAADBF"
      />
      <path
        d="M34.5 36.5C35.6046 36.5 36.5 35.6046 36.5 34.5C36.5 33.3954 35.6046 32.5 34.5 32.5C33.3954 32.5 32.5 33.3954 32.5 34.5C32.5 35.6046 33.3954 36.5 34.5 36.5Z"
        fill="#F2BF80"
      />
    </svg>
  );
};

export default watchSvg;
