import { Col, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { DetailWrap, StyledButton } from "./ApplicationDetails";
import { dateConverter, formatPhoneNumber } from "pages/utils/fn";

const EmployerDetails = ({
  onChangeTab,
  userDataObj,
  onApprovalSubmit,
  onDeclineSubmit,
}) => {
  const [employerData, setEmployerData] = useState([]);

  useEffect(() => {
    if (
      userDataObj &&
      Object.keys(userDataObj?.manual_verification_data?.employerDetails || {})
        .length
    ) {
      setEmployerData(userDataObj?.manual_verification_data?.employerDetails);
    }
  }, [userDataObj]);

  const onApproval = () => {
    if (userDataObj?.is_employment_verified !== 1) {
      onApprovalSubmit("employer_details", userDataObj?.user_id);
      message.info("Employment Detail Approved");
      // onChangeTab(4);
    } else {
      message.warn("Employment Detail Already Approved");
      // onChangeTab(4);
    }
  };

  const onDecline = () => {
    if (userDataObj?.is_employment_verified !== 2) {
      onDeclineSubmit("employer_details", userDataObj?.user_id);
      message.info("Employment Detail Declined");
    } else {
      message.warn("Employment Detail Already Declined");
    }
  };

  return (
    <>
      <DetailWrap>
        <Row>
          <Col span={24}>
            <h1>Employer Details</h1>
          </Col>
          {Object?.keys(employerData || {})?.length ? (
            <ViewAllRental>
              <AddedReference>
                <Row justify="space-between">
                  <Col>
                    <h2>Employer Details</h2>
                  </Col>
                </Row>
              </AddedReference>
              <ViewAddedReference>
                <Row justify="space-between" gutter={[15, 15]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    <Row justify="space-between" gutter={[2, 2]}>
                      <Col span={8}>
                        <DetailsBox>
                          <p>Business Name</p>
                        </DetailsBox>
                      </Col>
                      <Col span={16}>
                        <DetailsBox color="#000000">
                          <p>{employerData?.businessName}</p>
                        </DetailsBox>
                      </Col>
                      <Col span={8}>
                        <DetailsBox>
                          <p>Address</p>
                        </DetailsBox>
                      </Col>
                      <Col span={16}>
                        <DetailsBox color="#000000">
                          <p>{employerData?.address}</p>
                        </DetailsBox>
                      </Col>
                      <Col span={8}>
                        <DetailsBox>
                          <p>City</p>
                        </DetailsBox>
                      </Col>
                      <Col span={16}>
                        <DetailsBox color="#000000">
                          <p>{employerData?.city}</p>
                        </DetailsBox>
                      </Col>
                      <Col span={8}>
                        <DetailsBox>
                          <p>State</p>
                        </DetailsBox>
                      </Col>
                      <Col span={16}>
                        <DetailsBox color="#000000">
                          <p>{employerData?.state}</p>
                        </DetailsBox>
                      </Col>
                      <Col span={8}>
                        <DetailsBox>
                          <p>Zip</p>
                        </DetailsBox>
                      </Col>
                      <Col span={16}>
                        <DetailsBox color="#000000">
                          <p>{employerData?.zipcode}</p>
                        </DetailsBox>
                      </Col>
                      <Col span={8}>
                        <DetailsBox>
                          <p>Work Phone</p>
                        </DetailsBox>
                      </Col>
                      <Col span={16}>
                        <DetailsBox color="#000000">
                          <p>{formatPhoneNumber(employerData?.workPhone)}</p>
                        </DetailsBox>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={12}
                    xxl={12}
                    // style={{ marginTop: "22px" }}
                  >
                    <Row justify="space-between" gutter={[2, 2]}>
                      <Col span={8}>
                        <DetailsBox>
                          <p>Position</p>
                        </DetailsBox>
                      </Col>
                      <Col span={16}>
                        <DetailsBox color="#000000">
                          <p>{employerData?.position}</p>
                        </DetailsBox>
                      </Col>
                      <Col span={8}>
                        <DetailsBox>
                          <p>Gross Monthly Income</p>
                        </DetailsBox>
                      </Col>
                      <Col span={16}>
                        <DetailsBox color="#000000">
                          <p>{employerData?.grossMonthlyIncome}</p>
                        </DetailsBox>
                      </Col>
                      <Col span={8}>
                        <DetailsBox>
                          <p>Date Started</p>
                        </DetailsBox>
                      </Col>
                      <Col span={16}>
                        <DetailsBox color="#000000">
                          <p>{dateConverter(employerData?.dateStarted)}</p>
                        </DetailsBox>
                      </Col>
                      <Col span={8}>
                        <DetailsBox>
                          <p>Supervisor's Name</p>
                        </DetailsBox>
                      </Col>
                      <Col span={16}>
                        <DetailsBox color="#000000">
                          <p>{employerData?.supervisorsName}</p>
                        </DetailsBox>
                      </Col>
                      <Col span={8}>
                        <DetailsBox>
                          <p>Supervisor's Number</p>
                        </DetailsBox>
                      </Col>
                      <Col span={16}>
                        <DetailsBox color="#000000">
                          <p>
                            {formatPhoneNumber(employerData?.supervisorsNumber)}
                          </p>
                        </DetailsBox>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </ViewAddedReference>
            </ViewAllRental>
          ) : (
            <>
              <h2>No Data Found</h2>
            </>
          )}

          <Row
            justify="space-between"
            className="button__group"
            style={{ alignItems: "center" }}
            gutter={[20, 20]}
          >
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              xl={6}
              xxl={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <IoIosArrowBack
                className="nextback__icon"
                onClick={() => onChangeTab(2)}
              />
              <h3 onClick={() => onChangeTab(2)}>Back</h3>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
              <StyledButton
                bgColor={"#F67C36"}
                onClick={() => {
                  onApproval();
                }}
                disabled={userDataObj?.is_employment_verified === 1}
              >
                Approve
              </StyledButton>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
              <StyledButton
                bgColor={"#2B353F"}
                onClick={() => {
                  onDecline();
                }}
                disabled={userDataObj?.is_employment_verified !== 1}
              >
                Decline
              </StyledButton>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              xl={6}
              xxl={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <h3
                style={{ paddingRight: "15px" }}
                onClick={() => onChangeTab(4)}
              >
                Next
              </h3>
              <IoIosArrowForward
                className="nextback__icon"
                onClick={() => onChangeTab(4)}
              />
            </Col>
          </Row>
        </Row>
      </DetailWrap>
    </>
  );
};

export default EmployerDetails;

export const ViewAllRental = styled.div`
  width: 100%;
  margin-bottom: 25px;
  margin-bottom: ${({ mb }) => mb || "25px"};

  .less__svg {
    margin-bottom: 5px;
  }

  .ant-divider-horizontal {
    margin: 0;
  }
  h6 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #2b353f;
    margin-bottom: 0;
  }
`;

export const AddedReference = styled.div`
  background: #ffffff;
  border: 1px solid #dddddd;
  border-bottom: ${({ bb }) => bb || "1px solid #dddddd"};
  padding: 20px;
  cursor: pointer;
  span {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: #ffffff;
    background: #4160ac;
    padding: 2px 5px;
  }

  .prop_name {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
  }

  h3 {
    color: #2b353f;
    font-size: 18px;
    font-weight: 4000;
    line-height: 22px;
    margin-bottom: 0;
  }

  h5 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 10px;
  }
`;

export const ViewAddedReference = styled.div`
  width: -webkit-fill-available;
  background: #fafafa;
  border: 1px solid #dddddd;
  border-top: none;
  padding: 20px;
  margin-bottom: 25px;

  h2 {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #777777;
    margin-bottom: 5px;
  }
`;

export const DetailsBox = styled.div`
  background: #ffffff;
  padding: 10px 12px;
  min-height: 37px;
  ${"" /* max-height: 37px; */}
  ${"" /* overflow: auto; */}
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${({ color }) => color || "#777777"};
    margin-bottom: 0;
  }
`;
