import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpAPI } from "api";
import { httpAPIInvite } from "../../api/index";

const initialState = {
  user: null,
  accessToken: "",
  loading: false,
  loadingApartment: false,
  isLoggedIn: false,
  error: null,
  emailVerification: false,
  modules: {},
  allOrganization: {},
  apartment: {},
  allApartment: {},
  allUnit: {},
  allNotificatios: {},
  allTenants: {},
};

export const login = createAsyncThunk("user/login", async (data, thunkAPI) => {
  const response = await httpAPI("/signIn", data);
  return response.data;
});

export const forgotPasswordOtp = createAsyncThunk(
  "forgot/password/otp",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI("/forgot-password", data, "POST", true);
      return { ...response.data };
    } catch (error) {
      if (error) {
        // return error;
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getApplications = createAsyncThunk(
  "/applications",
  async (data, thunkAPI) => {
    try {
      // let id = localStorage.getItem("user_id");
      const response = await httpAPIInvite(
        "/avenew-tool/applications",
        data,
        "GET"
      );
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTenants = createAsyncThunk(
  "/tenants",
  async (data, thunkAPI) => {
    try {
      // let id = localStorage.getItem("user_id");
      const response = await httpAPI("/all-users", data, "POST");
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUserData = createAsyncThunk(
  "/user/data",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("app_id");
      const response = await httpAPIInvite(
        `/user/application/${id}`,
        data,
        "GET"
      );
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getOrganizaion = createAsyncThunk(
  "/user/organizaion",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI(`/organizations/all`, data, "GET");
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const verifyTenantData = createAsyncThunk(
  "user/tenant/update",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPIInvite(
        `/user/application/update`,
        data,
        "POST"
      );
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const me = createAsyncThunk("user/me", async (data, thunkAPI) => {
  try {
    let id = localStorage.getItem("user_id");
    const response = await httpAPI(`/user/${id}`, data, "GET");
    return { ...response.data };
  } catch (error) {
    // message.error(error.Error || error.message);
    return thunkAPI.rejectWithValue(error);
  }
});

export const counterSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.clear();
      state.user = null;
      state.accessToken = "";
      state.loading = false;
      state.isLoggedIn = false;
      state.error = null;
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, { payload }) => {
      if (!payload.error && !payload.Error) {
        state.loading = false;

        localStorage.setItem("idToken", payload.AuthenticationResult?.IdToken);
        localStorage.setItem(
          "accessToken",
          payload.AuthenticationResult?.AccessToken
        );
        localStorage.setItem("user_id", payload.user_id);
        state.isLoggedIn = true;
        state.accessToken = payload.AuthenticationResult?.AccessToken;
      }
    },
    [login.pending]: (state) => {
      state.loading = true;
    },
    [login.rejected]: (state, { payload, ...options }) => {
      state.loading = false;
      state.error = payload;
      state.isLoggedIn = false;
      localStorage.setItem("isLoggedIn", false);
    },
    [me.fulfilled]: (state, { payload }) => {
      state.user = payload;
      state.loading = false;
    },
    [me.pending]: (state) => {
      state.loading = true;
    },
    [me.rejected]: (state, { payload }) => {
      localStorage.clear();
      state.user = null;
      state.accessToken = "";
      state.loading = false;
      state.isLoggedIn = false;
      state.error = null;
    },
    // [resetPassword.rejected]: (state, { payload }) => {
    // },
    // [getAllOrg.fulfilled]: (state, { payload }) => {
    //   state.allOrganization = payload;
    // },
    // [getAllOrg.rejected]: (state, { payload }) => {
    //   localStorage.clear();
    // },
    // [getAllOrgId.fulfilled]: (state, { payload }) => {
    //   state.loading = false;
    // },
    // [getAllOrgId.rejected]: (state, { payload }) => {
    //   state.loading = false;
    // },
    // [getAllOrgId.pending]: (state, { payload }) => {
    //   state.loading = true;
    // },
    // [getApartment.fulfilled]: (state, { payload }) => {
    //   state.apartment = payload;
    //   state.loading = false;
    // },
    // [getApartment.pending]: (state, { payload }) => {
    //   state.loading = true;
    // },
    // [getApartment.rejected]: (state, { payload }) => {
    //   state.loading = false;
    //   localStorage.clear();
    // },
    // [getAllTenants.rejected]: (state, { payload }) => {
    //   localStorage.clear();
    // },
    // [getSingleApartment.fulfilled]: (state, { payload }) => {
    //   state.property = payload;
    //   state.loading = false;
    // },
    // [getSingleApartment.pending]: (state, { payload }) => {
    //   state.loading = true;
    // },
    // [getSingleApartment.rejected]: (state, { payload }) => {
    //   state.loading = false;
    //   localStorage.clear();
    // },
    // [getAllUnits.fulfilled]: (state, { payload }) => {
    //   state.units = payload;
    //   state.loading = false;
    // },
    // [getAllUnits.pending]: (state, { payload }) => {
    //   state.loading = true;
    // },
    // [getAllUnits.rejected]: (state, { payload }) => {
    //   state.loading = false;
    //   // localStorage.clear();
    // },
    // [getAllPropId.fulfilled]: (state, { payload }) => {
    //   state.loadingApartment = false;
    // },
    // [getAllPropId.pending]: (state, { payload }) => {
    //   state.loadingApartment = true;
    // },
    // [getAllPropId.rejected]: (state, { payload }) => {
    //   state.loadingApartment = false;
    // },
    // [getAllApartment.fulfilled]: (state, { payload }) => {
    //   state.allApartment = payload;
    //   state.loading = false;
    // },
    // [getAllApartment.pending]: (state, { payload }) => {
    //   state.loading = true;
    // },
    // [getAllApartment.rejected]: (state, { payload }) => {
    //   state.loading = false;
    // },
    // [getUnitsByUserId.fulfilled]: (state, { payload }) => {
    //   state.allUnit = payload;
    //   state.loading = false;
    // },
    // [getUnitsByUserId.pending]: (state, { payload }) => {
    //   state.loading = true;
    // },
    // [getUnitsByUserId.rejected]: (state, { payload }) => {
    //   state.loading = false;
    // },
    // [invoicesByTenant.fulfilled]: (state, { payload }) => {
    //   state.invoices = payload;
    //   state.loading = false;
    // },
    // [invoicesByTenant.pending]: (state, { payload }) => {
    //   state.loading = true;
    // },
    // [invoicesByTenant.rejected]: (state, { payload }) => {
    //   state.loading = false;
    // },
    // [getNotifications.fulfilled]: (state, { payload }) => {
    //   state.allNotificatios = payload;
    //   state.loading = false;
    // },
    [getTenants.fulfilled]: (state, { payload }) => {
      state.allTenants = payload;
      state.loading = false;
    },
    // [getNotifications.pending]: (state, { payload }) => {
    //   state.loading = true;
    // },
    // [getNotifications.rejected]: (state, { payload }) => {
    //   state.loading = false;
    // },
    // [getAllTransactions.rejected]: (state, { payload }) => {
    //   localStorage.clear();
    // },
    // [getAllTransactionsByOrg.rejected]: (state, { payload }) => {
    //   localStorage.clear();
    // },
    // [getAllOrg.pending]: (state) => {
    //   state.loading = true;
    // },
    // [getAllOrg.rejected]: (state, { payload }) => {
    // },
  },
});

// Action creators are generated for each case reducer function
export const { logout } = counterSlice.actions;

export default counterSlice.reducer;
