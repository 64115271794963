import React, { useState } from "react";
import Chart from "react-apexcharts";
import { ChartBoardWrap, SelectWrap } from "./DashboardStyle";
import { Row, Col, Select } from "antd";
import styled from "styled-components";
import { ContainerWrap } from "../../ui/Layout/ContainerWrap";

function ChartBoard(props) {
  const { Option } = Select;

  // data: [75, 77, 78, 25, 12, 27, 18, 20, 80, 100, 75, 76],

  // data: [75, 0, 48, 49, 48, 98, 95, 45, 75, 45, 98, 80],

  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState({
    series: [
      {
        name: "My Property",
        data: [75, 77, 78, 25, 12, 27, 18, 20, 80, 100, 75, 76, 78],
      },
      {
        name: "Comp Set",
        data: [75, 0, 48, 49, 48, 98, 95, 45, 75, 45, 98, 80, 85],
      },
    ],
    options: {
      colors: ["#F67C36", "#4160AC"],
      fill: {
        colors: ["#F67C36", "#4160AC"],
        // type: "gradient",
        // gradient: {
        //   shadeIntensity: 1,
        //   opacityFrom: 1,
        //   opacityTo: 0.8,
        //   stops: [0, 100],
        // },
      },
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2021/01/19",
          "2021/02/29",
          "2021/03/09",
          "2021/04/20",
          "2021/05/07",
          "2021/06/25",
          "2021/07/30",
          "2021/08/15",
          "2021/09/17",
          "2021/10/22",
          "2021/11/06",
          "2021/12/19",
          "2022/01/19",
        ],
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
    },
  });

  return (
    <ChartBoardWrap>
      <ContainerWrap
        brRadius="5px"
        minH="432px"
        bgcolor="#ffffff"
        paddings="10px"
      >
        <Row justify="space-between">
          <Col
            xl={{ span: 11 }}
            md={{ span: 7 }}
            sm={{ span: 11 }}
            xs={{ span: 8 }}
          >
            <h2> Occupancy</h2>
          </Col>
          {/* <Col span={10} style={{ justifyContent: "space-between" }}> */}
          {/* <Col className="line__titleA" span={6}>
              <GoPrimitiveDot style={{ color: "#858585" }} />
              <p>My Property</p>
            </Col>
            <Col className="line__titleB" span={6}>
              <GoPrimitiveDot style={{ color: "#c5c5c5" }} />
              <p>Comp Set</p>
            </Col> */}

          <Col
            xl={{ span: 6 }}
            md={{ span: 7 }}
            sm={{ span: 7 }}
            xs={{ span: 14 }}
          >
            <SelectWrap
              placeholder="My Property vs Comp Set"
              // onChange={}
              allowClear
              bgcolor="#E3E9F4"
            >
              <Option value="East Quarter Residences">
                East Quarter Residences
              </Option>
              <Option value="27TwentySeven Apartment Homes">
                27TwentySeven Apartment Homes
              </Option>
              <Option value="The Heights at Park Lane Apartments">
                The Heights at Park Lane Apartments
              </Option>
            </SelectWrap>
          </Col>
          {/* </Col> */}
        </Row>
        <Row>
          <Col span={24}>
            <ChartWrap>
              <Chart
                id="chart"
                options={state.options}
                series={state.series}
                type="area"
                height={350}
                width="100%"
                style={{ minWidth: "100%", minHeight: "365px" }}
              />
            </ChartWrap>
          </Col>
        </Row>
      </ContainerWrap>
    </ChartBoardWrap>
  );
}

export default ChartBoard;

export const ChartWrap = styled.div`
  min-width: 100%;
  height: 100%;
`;
