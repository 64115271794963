import styled from "styled-components";

// custom container
export const ContainerWrap = styled.div`
  min-height: ${({ minH }) => minH || "calc(100vh - 122px)"};
  padding: ${({ padding }) => padding || "30px"};
  background-color: ${({ bgcolor }) => (bgcolor ? bgcolor : "#eeeeee")};
  border-radius: ${({ brRadius }) => (brRadius ? brRadius : "0")};

  @media (max-width: 1550px) {
    padding: ${({ padding }) => padding || "24px"};
  }

  @media (max-width: 991.98px) {
    padding: ${({ paddings }) => paddings || "0px"};
  }
`;
