import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpAPI, httpAPIInvite } from "api";
// import { httpAPI, httpAPIInvite } from "../../api/index";

const initialState = {
  loading: false,
  isLoggedIn: false,
  error: null,
  data: [],
};

export const fetchAllApplications = createAsyncThunk(
  "applications/fetchAllApplications",
  async (data, thunkAPI) => {
    const response = await httpAPIInvite(
      "/applications/admin/b528f18f-af75-4d7b-bd05-7d3e6754162c",
      {},
      "GET"
    );
    return response.data;
  }
);

export const applicationsSlice = createSlice({
  name: "applications",
  initialState,
  reducers: {
    // logout: (state) => {
    //   localStorage.clear();
    //   state.user = null;
    //   state.accessToken = "";
    //   state.loading = false;
    //   state.error = null;
    // },
  },
  extraReducers: {
    [fetchAllApplications.fulfilled]: (state, { payload }) => {
      if (!payload.error && !payload.Error) {
        state.loading = false;
        state.data = payload;
      }
    },
    [fetchAllApplications.pending]: (state) => {
      state.loading = true;
    },
    [fetchAllApplications.rejected]: (state, { payload, ...options }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

// Action creators are generated for each case reducer function
// export const { logout } = counterSlice.actions;

export default applicationsSlice.reducer;
