import React, { useEffect } from "react";
import Content from "./Content";
import Header from "./header/Header";
import Sidebar from "./Sidebar";
import { useLocation } from "react-router";
import { useWindowSize } from "pages/utils/hooks/useWindowSize";

function Layout() {
  // Funtion to open slider
  const openSider = (e) => {
    e.preventDefault();
    document.body.classList.toggle("openSider");
  };

  // To open message from sider menu option
  const msgOpenSider = (e) => {
    e.preventDefault();
    document.body.classList.toggle("msgOpenSider");
  };

  const { width } = useWindowSize();
  const location = useLocation();

  useEffect(() => {
    if (width < 992) {
      document.body.classList.add("openSider");
    }
  }, [width, location.pathname]);
  useEffect(() => {
    if (width < 992) {
      document.body.classList.add("msgOpenSider");
    }
  }, [width, location.pathname]);
  return (
    <div className="content-wrap">
      <div className="back-drop" onClick={openSider}></div>
      <div className="msg-back-drop" onClick={msgOpenSider}></div>
      <Header />
      <Sidebar />
      <Content />
    </div>
  );
}

export default Layout;
