import styled from "styled-components";

// main container
export const Container = styled.div`
  min-height: ${({ minH }) => minH || "calc(100vh - 80px)"};
  padding: ${({ padding }) => padding || "50px 50px 50px 330px"};
  background-color: ${({ bgcolor }) => (bgcolor ? bgcolor : "#eeeeee")};
  border-radius: ${({ brRadius }) => (brRadius ? brRadius : "0")};

  .ant__spin {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }

  .ant-input-search .ant-input-affix-wrapper {
    height: 40px;
    background-color: #fff;
  }
  .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    background-color: #fff;
  }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  }
  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    display: none;
  }

  .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    background: #fff;
    border-radius: 5px;
    border: none;
  }

  @media (max-width: 1550px) {
    padding: ${({ paddingSm }) => paddingSm || "40px 40px 40px 265px"};
    min-height: ${({ minH }) => minH || "calc(100vh - 65px)"};
  }

  @media (max-width: 991.98px) {
    padding: ${({ paddings }) => paddings || "15px"};
  }
`;
