import React, { useEffect } from "react";
import { Row, Col, Select } from "antd";
import { Link, useLocation } from "react-router-dom";
import { Heading } from "ui/Heading/Heading";
import { SelectWrap, RangePickerWrap, ExportButton } from "./DashboardStyle";
import { UploadOutlined } from "@ant-design/icons";
import ChartBoard from "./ChartBoard";
import TableBoard from "./TableBoard";
import { Container } from "ui/Layout/Container";
import { useWindowSize } from "pages/utils/hooks/useWindowSize";
import FetureSoon from "components/feture-soon/FetureSoon";

function Dashboard() {
  const { Option } = Select;
  const { width } = useWindowSize();
  const location = useLocation();

  // useEffect(() => {
  //   dispatch(me());
  // }, []);

  useEffect(() => {
    if (width < 1550 && width > 991) {
    } else {
    }
  }, [width, location.pathname]);

  return (
    <Container minH="100vh">
      <FetureSoon />
      <Row justify="space-between">
        <Col
          xl={{ span: 4 }}
          md={{ span: 4 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Heading>
            <Link to="/">Benchmark</Link>
          </Heading>
        </Col>

        {/* Select Propety */}
        <Col
          xl={{ span: 14 }}
          md={{ span: 18 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          {/* <FilterWrap> */}
          <Row gutter={[15, 15]} justify="space-between">
            <Col
              xl={{ span: 6 }}
              md={{ span: 6 }}
              sm={{ span: 6 }}
              xs={{ span: 12 }}
              className="mobile__wd"
            >
              <SelectWrap
                placeholder="Select Propety"
                // onChange={}
                allowClear
              >
                <Option value="East Quarter Residences">
                  East Quarter Residences
                </Option>
                <Option value="27TwentySeven Apartment Homes">
                  27 TwentySeven Apartment Homes
                </Option>
                <Option value="The Heights at Park Lane Apartments">
                  The Heights at Park Lane Apartments
                </Option>
              </SelectWrap>
            </Col>

            {/* Occupancy */}
            <Col
              xl={{ span: 6 }}
              md={{ span: 6 }}
              sm={{ span: 6 }}
              xs={{ span: 12 }}
              className="mobile__wd"
            >
              <SelectWrap
                placeholder="Occupancy"
                // onChange={}
                allowClear
              >
                <Option value="Residences">Residences</Option>
                <Option value="Apartment Homes">Apartment Homes</Option>
                <Option value="The Heights">The Heights </Option>
              </SelectWrap>
            </Col>
            {/* Range picker */}
            <Col
              xl={{ span: 8 }}
              md={{ span: 8 }}
              sm={{ span: 8 }}
              xs={{ span: 16 }}
            >
              <RangePickerWrap format="DD MMM, YYYY" />
            </Col>
            <Col
              xl={{ span: 4 }}
              md={{ span: 4 }}
              sm={{ span: 4 }}
              xs={{ span: 8 }}
            >
              <ExportButton icon={<UploadOutlined />}>Export</ExportButton>
            </Col>
          </Row>
          {/* </FilterWrap> */}
        </Col>
      </Row>
      <ChartBoard />
      <TableBoard />
    </Container>
  );
}

export default Dashboard;
