import { Col, Image, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import { ImagePreview } from "components/imagePreview";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { DetailWrap, StyledButton } from "./ApplicationDetails";

const PayStubsDetails = ({
  onChangeTab,
  userDataObj,
  onApprovalSubmit,
  onDeclineSubmit,
}) => {
  const [payStub1, setPayStub1] = useState({});
  const [payStub2, setPayStub2] = useState({});

  useEffect(() => {
    if (
      userDataObj &&
      Object.keys(userDataObj?.manual_verification_data?.payStubs || {}).length
    ) {
      setPayStub1(userDataObj?.manual_verification_data?.payStubs?.payStub1);
      setPayStub2(userDataObj?.manual_verification_data?.payStubs?.payStub2);
    }
  }, [userDataObj]);

  const onApproval = () => {
    if (userDataObj?.is_paystub_verified !== 1) {
      onApprovalSubmit("pay_stubs", userDataObj?.user_id);
      message.info("PayStubs Approved");
      // onChangeTab(3);
    } else {
      message.warn("Pay stub Already Approved");
      // onChangeTab(3);
    }
  };

  const onDecline = () => {
    if (userDataObj?.is_paystub_verified !== 2) {
      onDeclineSubmit("pay_stubs", userDataObj?.user_id);
      message.info("PayStubs Declined");
    } else {
      message.warn("Pay stub Already Declined");
    }
  };

  const paystub = Object.keys(
    userDataObj?.manual_verification_data?.payStubs || {}
  ).length;

  return (
    <>
      <DetailWrap>
        <Row>
          <Col span={24}>
            <h1>PayStubs</h1>
          </Col>
          {userDataObj?.bank_verification_data?.is_verified === 1 ? (
            <Col span={24}>
              <a
                style={{ padding: "20px 20px" }}
                href="https://plaid.com/documents/sample-bank-income.pdf"
                target="blank"
              >
                Download Instant Bank Data
              </a>
            </Col>
          ) : (
            ""
          )}
          {paystub ? (
            <Row gutter={[20, 20]}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <ImagePreview
                  // key={idx}
                  src={payStub1?.path}
                  downloadImgUrl={payStub1?.path}
                  style={{
                    width: "100%",
                    padding: "20px",
                    minHeight: "400px",
                    maxHeight: "400px",
                  }}
                  isCoverDisProp={false}
                  isDeleteDisProp={false}
                  isDownload={true}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <ImagePreview
                  // key={idx}
                  src={payStub2?.path}
                  downloadImgUrl={payStub2?.path}
                  style={{
                    width: "100%",
                    padding: "20px",
                    minHeight: "400px",
                    maxHeight: "400px",
                  }}
                  isCoverDisProp={false}
                  isDeleteDisProp={false}
                  isDownload={true}
                />
              </Col>
            </Row>
          ) : (
            <>
              <h2>No Data Found</h2>
            </>
          )}

          <Row
            justify="space-between"
            className="button__group"
            style={{ alignItems: "center" }}
            gutter={[20, 20]}
          >
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              xl={6}
              xxl={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <IoIosArrowBack
                className="nextback__icon"
                onClick={() => onChangeTab(1)}
              />
              <h3 onClick={() => onChangeTab(1)}>Back</h3>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
              <StyledButton
                bgColor={"#F67C36"}
                onClick={() => {
                  onApproval();
                }}
                disabled={userDataObj?.is_paystub_verified === 1}
              >
                Approve
              </StyledButton>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
              <StyledButton
                bgColor={"#2B353F"}
                onClick={() => {
                  onDecline();
                }}
                disabled={userDataObj?.is_paystub_verified !== 1}
              >
                Decline
              </StyledButton>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              xl={6}
              xxl={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <h3
                style={{ paddingRight: "15px" }}
                onClick={() => onChangeTab(3)}
              >
                Next
              </h3>
              <IoIosArrowForward
                className="nextback__icon"
                onClick={() => onChangeTab(3)}
              />
            </Col>
          </Row>
        </Row>
      </DetailWrap>
    </>
  );
};

export default PayStubsDetails;
